@use "assets/css/constants.scss" as *;

.room-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  background-color: $color-purple-main;
  padding: 0 24px;

  > h2 {
    color: $color-white;
    font-size: 24px;
    font-weight: 800;
  }

  .actions {
    display: flex;
    gap: 16px;

    .room-info {
      display: flex;
      align-items: center;
      background-color: $color-white;
      height: 40px;
      border-radius: 12px;
      padding: 0 24px;
      gap: 16px;

      > div {
        display: flex;
        align-items: center;
        gap: 8px;
        color: $color-purple-main;

        span {
          font-weight: 800;
          font-size: 18px;
        }
      }

      .code {
        color: $color-black-main;
        font-size: 16px;
      }
    }

    .close-room {
      background-color: #da4238;
      border-radius: 8px;
      border: 1px solid $color-white;
      color: $color-white;
      height: 40px;
      font-size: 16px;
      padding: 0 24px;
    }
  }
}
