.modal-wrapper {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;

  .modal {
    display: flex;
    flex-direction: column;
    max-width: 650px;
    gap: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 998;
    min-width: 320px;
    background-color: #fff;
    border-radius: 6px;
    padding: 24px;

    h1.title {
      color: #161616;
      font-size: 20px;
      font-weight: 800;
      line-height: 30px;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 997;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
  }

  &.displayed {
    display: block;
  }
}