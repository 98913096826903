.filters {
  display: flex;
  padding-right: 24px;
  padding-left: 12px;
  padding-top: 20px;
  position: absolute;
  width: 25%;
  z-index: 10;
  overflow-y: auto;
  flex-direction: column;
  background-color: #e8f5fe;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  gap: 12px;

  .rows {
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 12px;

    .param {
      width: 25%;

      .MuiAutocomplete-inputRoot {
        flex-wrap: nowrap;
      }
      .MuiInputBase-root,
      .date-picker {
        background-color: #ffffff;
        width: 100%;
        height: 40px;
      }
      .input-title {
        font-family: Avenir;
        font-size: 12px;
        margin-bottom: 2px;
        color: #4d4f53;
      }

      .range-date {
        &:hover {
          cursor: pointer;
        }
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 40px;
        background-color: #ffffff;
        border-radius: 4px;
        border: 1px solid rgb(180, 180, 180);
        padding-left: 14px;
        padding-right: 14px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.87);
      }
    }

    .parameter {
      width: 25%;

      &.value-filter {
        input,
        .MuiSelect-select {
          padding-left: 8px;
        }

        .textfield input {
          padding-left: 4px;
        }
      }
    }
  }
}
