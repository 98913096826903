@import "assets/css/constants.scss";

.authorization-toggle {
  width: 100%;
  padding: 8px;
  background-color: $color-snci-bg;
  border-radius: 12px;

  .custom-toggle {
    > span {
      font-size: 18px;
      font-weight: 800;
      color: $color-black-main;
    }

    > .switch {
      margin-top: 16px;
    }
  }
}
