$backgroundColor: #f5f9ff;

.table-container {
  height: 100%;

  .MuiTableHead-root {
    .MuiTableCell-root {
      border: 0;
    }

    .MuiTableCell-paddingCheckbox {
      background-color: $backgroundColor;
    }

    .MuiTableCell-root:not(.MuiTableCell-paddingCheckbox) {
      background-color: $backgroundColor;
      font-size: 18px;
    }
  }

  .MuiTableBody-root {
    overflow-wrap: anywhere;

    .MuiTableCell-root:nth-of-type(4) {
      text-decoration: underline;
    }
  }
  ~ .MuiTablePagination-root {
    overflow: hidden;
    border: 0;
  }
}
