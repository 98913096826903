@import "assets/css/constants.scss";

.toolbar-panel {
  display: flex;
  height: 100%;
  border-width: 0.5px 0 0.5px 0.5px;
  border-style: solid;
  border-color: #d7e1f3;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  background-color: #ffffff;

  .sidebar {
    padding: 8px 0;
    width: 54px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-right: 0.5px solid #d7e1f3;
    background-color: #ffffff;
    gap: 16px;

    button {
      display: flex;
      cursor: pointer;
      padding: 8px;
      border: none;
      background-color: inherit;
      border-radius: 8px;
      transition: filter 0.2s ease;

      &:disabled {
        opacity: 0.5;

        &:hover {
          cursor: not-allowed;
        }
      }

      &:hover:not(.selected) {
        filter: brightness(100%);
        path {
          filter: brightness(90%);
        }
      }

      path {
        fill: $color-purple-main;
      }

      &.selected {
        background-color: $color-purple-main;
        path {
          fill: #ffffff;
        }
      }
    }

    &:has(~ .displayed) {
      cursor: pointer;
    }
  }

  .panels-manager {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    flex: 0;
    width: 0;
    overflow-x: hidden;
    background-color: #ffffff;
    min-width: 0;
    transition: width 0.3s ease-in-out;
    scrollbar-width: thin;

    .panel-title {
      padding: 10px 62px 24px 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      max-height: 100px;
      // opacity: 0;
      // opacity: 0.3s ease-in-out;

      button {
        height: 24px;
        min-height: unset;
      }
    }

    &.displayed {
      width: 345px;

      flex: 2;

      // * {
      //   transition-delay: 0.18s;
      //   opacity: 1;
      // }
    }

    // * {
    //   opacity: 0;
    //   transform: 0.1s ease-in-out;
    // }
  }
}
