@import "assets/css/constants.scss";

.snci {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #000000;

  button {
    transition: filter 0.2s ease;
    &:hover:not(:disabled) {
      filter: brightness(90%);
      cursor: pointer;
    }

    &:disabled {
      opacity: 0.4;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  .hidden-input {
    opacity: 0;
    border: none;
    width: 0;
    height: 0;
    position: absolute;
  }

  .screen-wrapper {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    background-color: #f5f6fb;
    padding: 4px 0 4px 4px;
    gap: 4px;
    height: 100%;
    width: 100vw;

    .toolbar {
      padding: 0px 18px;
      background: #ffffff;
      border-radius: 12px;
      border: 0.5px solid #d7e1f3;
      height: 52px;
      display: flex;
      width: calc(100% - 4px);
      justify-content: space-between;

      align-items: center;
      gap: 32px;

      .controls {
        button {
          font-size: 14px;
          font-weight: 350;
        }
      }

      .secondary-controls {
        display: flex;
        gap: 32px;
      }

      .sound {
        display: flex;
        align-items: center;
        width: 56px;
        height: 32px;
        background-color: $color-purple-main;
        border: none;
        border-radius: 8px;
        padding: 2px 16px;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    .content-wrapper {
      display: flex;
      gap: 4px;
      flex: 1;
      overflow: hidden;

      .iframe-container {
        flex: 4;
        border-radius: 12px;
        overflow: hidden;

        #iframe-wrapper {
          border: none;
          width: 100%;
          height: 100%;
          background-color: #000000;
          ::-webkit-scrollbar {
            width: 10px; /* Largeur de la scrollbar */
            height: 10px; /* Hauteur pour les scrollbars horizontales */
          }

          ::-webkit-scrollbar-thumb {
            background: red; /* Couleur de la poignée (thumb) */
            border-radius: 5px; /* Coins arrondis */
          }

          ::-webkit-scrollbar-thumb:hover {
            background: #333; /* Couleur au survol */
          }

          ::-webkit-scrollbar-track {
            background: #f1f1f1; /* Couleur de l'arrière-plan */
            border-radius: 5px; /* Coins arrondis */
          }
          .Programmation {
            ::-webkit-scrollbar {
              width: 10px; /* Largeur de la scrollbar */
              height: 10px; /* Hauteur pour les scrollbars horizontales */
            }

            ::-webkit-scrollbar-thumb {
              background: red; /* Couleur de la poignée (thumb) */
              border-radius: 5px; /* Coins arrondis */
            }

            ::-webkit-scrollbar-thumb:hover {
              background: #333; /* Couleur au survol */
            }

            ::-webkit-scrollbar-track {
              background: #f1f1f1; /* Couleur de l'arrière-plan */
              border-radius: 5px; /* Coins arrondis */
            }
          }
        }
      }
    }

    .panel-container {
      // 52px toolbar
      height: calc(100svh - $topbar-height - 52px - 12px);
    }
  }
}
