.tab-element {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 20px;

  &:hover, &.selected {
    background-color: var(--color-grey-800);
  }

  .title {
    color: var(--color-lighter-grey);
    font-size: 14px;
    font-weight: 900;
    font-family: Avenir;
  }
}