.logs {
  overflow-y: hidden;
  overflow-x: auto;

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 4px;

    .buttons {
      display: flex;
      background-color: #ffffff;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      border-radius: 8px;
      height: 100%;
      padding: 0 8px 0 16px;

      span {
        font-weight: 400;
      }

      .MuiButtonBase-root.simple-button {
        background-color: #d8d7f8;
        color: #212731;
      }
    }
  }
  .controller {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
  }
}
