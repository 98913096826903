@import 'assets/css/constants.scss';

.version-input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
  position: relative;
  background: #F5F9FF;

  input {
    height: 50px;
    border: 1px solid rgb(217, 217, 217);
    border-radius: 6px;
    font-size: 32px;
    font-weight: 400;
    color: rgb(22, 22, 22);
    transition: all 0.15s ease-in-out;
    background: #F5F9FF;

    &:focus {
      border-color: $color-main;
    }
    &:disabled {
      border: none;
      background: #F5F9FF;
    }
  }
  .icon {
    position: absolute;
    right: 0;
  }
}