@import 'assets/css/constants.scss';

.message-filter {
	display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
	width: 256px;
  position: relative;

  input {
    width: 100%;
    height: 50px;
    border: 1px solid rgb(217, 217, 217);
    border-radius: 6px;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 400;
    color: rgb(22, 22, 22);
    transition: all 0.15s ease-in-out;

    &:focus {
      border-color: $color-main;
    }
  }
  .icon {
    position: absolute;
    right: 12px;
  }
}