@import 'assets/css/constants.scss';


.comments {
	margin-top: 68px;
	min-width: 640px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	.comment-input {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 25px;
		position: relative;
	
		textarea {
			width: 100%;
			border: 1px solid rgb(217, 217, 217);
			border-radius: 6px;
			padding: 15px 60px 0 20px;
			font-size: 14px;
			font-weight: 400;
			color: rgb(22, 22, 22);
			resize: vertical;
	
			&:focus {
				border-color: $color-main;
			}
		}
		.icon {
			position: absolute;
			right: 10px;
		}
	}
}