.date-picker {
  height: 36px;
  border-radius: 4px;
  font-family: Avenir;
  background-color: var(--color-white);

  .MuiInputBase-root {
    padding: 0 6px;
    input {
      caret-color: transparent;
      padding: 8px;
    }

    :hover {
      cursor: pointer;
    }
  }

  + .calendar-wrapper {
    position: fixed;
    top: 223;
  }
}
