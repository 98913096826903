@import "assets/css/constants.scss";

.information-message {
  display: flex;
  flex-shrink: 0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 1px 4px 0px #0000001a;

  > .banner {
    width: 5px;
  }

  &.info {
    > .banner {
      background-color: $color-valid;
    }
  }

  &.warning {
    > .banner {
      background-color: $color-info;
    }
  }

  .message {
    flex: 1;
    padding: 12px 20px;
    background-color: white;
    font-size: 14px;

    &.bold {
      font-weight: 800;
    }
  }
}
