@import "assets/css/constants.scss";

.messages-section {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;

  .header {
    display: flex;
    align-items: center;
    background: black;
    color: white;
    padding: 10px 20px;
    border-radius: 6px 6px 0 0;
  }
  .header div:first-child {
    min-width: 160px;
  }

  .messages {
    padding: 20px;
    border: 1px solid rgb(217, 217, 217);
    border-radius: 0 0 6px 6px;
    height: 100%;
    background: white;
  }
  .message {
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    span:first-child {
      min-width: 160px;
    }
  }
}
