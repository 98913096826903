@import "assets/css/constants.scss";

header {
  background-color: #01080e;
  height: $topbar-height;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 990;
  padding: 0 24px;
  display: flex;
  align-items: center;

  *,
  .MuiButtonBase-root {
    color: white;
  }

  .MuiButtonBase-root {
    font-weight: 800;
  }

  .logos {
    display: flex;
    gap: 16px;

    img {
      width: 48px;
    }

    h1 {
      color: white;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.25;
      margin: 0;
      text-transform: uppercase;
    }

    * {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  > p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 250px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.15s ease-in-out;
  }

  // target div AccountMenu wrapper
  div:last-of-type {
    margin-left: auto;
  }
}

@media screen and (max-width: 768px) {
  header {
    > p {
      text-align: left;
      left: 115px;
      transform: translate(0%, -50%);
    }
  }
}

@media screen and (max-width: 600px) {
  header {
    > p {
      width: 150px;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 450px) {
  header {
    > p {
      opacity: 0;
    }

    .logos {
      display: none;
    }
  }
}
