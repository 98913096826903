.interface-wrapper {
  background-color: #dad8cf;
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }

  .form {
    height: fit-content;
  }
}
