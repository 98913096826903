@use "assets/css/constants.scss" as *;

.modal:has(.confirm-popup) {
  width: 30%;

  .confirm-popup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 36px;

    .error {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fddcda;
      color: $color-error;
      height: 10vh;
      width: 100%;
      padding: 10px;

      svg {
        font-size: 76px;
      }
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      gap: 36px;

      .header {
        font-size: 18px;
        font-weight: 800;
      }

      .body {
        color: #212731;
        font-size: 14px;
        font-weight: 350;
      }
    }

    .leave-buttons {
      display: flex;
      gap: 14px;

      > button.simple-button {
        text-decoration: none;
        background-color: $color-error;
        border-radius: 12px;
        color: #ffffff;
        padding-left: 28px;
        padding-right: 28px;

        &:hover {
          filter: brightness(90%);
        }
      }
    }
  }
}
