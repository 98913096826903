.time-tools {
  z-index: 9;
  bottom: 19px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 802px;
  min-width: 320px;
  gap: 20px;
  color: white;
  padding: 16px;
  border-radius: 4px;
  background: #050d12;
  box-shadow: 3px 4px 4px 0px rgba(0, 0, 0, 0.08),
    0px 1px 5px 0px rgba(0, 0, 0, 0.06);

  position: fixed;
  bottom: -80px;
  transition: bottom 0.3s ease;

  &:hover {
    bottom: 5px;
  }

  #tooltip {
    position: absolute;
    background-color: #ffffff;
    color: #050d12;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    transform: translate(-50%, -100%);
    bottom: 20px;
  }

  .tools {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;

    .actions {
      display: flex;
      gap: 8px;
      align-items: center;

      .update-time-button,
      .pause-button {
        width: 32px;
        height: 32px;
        position: relative;
        border-radius: 36px;
        border: 1px solid rgba(205, 205, 205, 0.3);
        background: rgba(255, 255, 255, 0.1);
        cursor: pointer;
        transition: all 0.1s ease;

        span {
          font-size: 9px;
          color: #e0e0e0;
          position: absolute;
          user-select: none;
        }

        svg {
          position: absolute;
        }

        &:first-of-type {
          span {
            top: 11px;
            left: 9.5px;
          }

          svg {
            left: 1px;
            top: 2px;
          }
        }

        &:last-of-type {
          span {
            top: 11px;
            left: 10px;
          }

          svg {
            left: 2.5px;
            top: 2px;
          }
        }

        &:hover {
          transform: scale(1.08);
        }
      }
    }
  }

  .progress-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .progress {
      width: 100%;
      border-radius: 4px;
      background: #fff;
      height: 6px;
      cursor: pointer;

      .progress-bar {
        position: relative;
        height: 100%;
        border-radius: 4px;
        background: #1775e0;
        width: 0%;

        .thumb {
          position: absolute;
          top: 50%;
          left: 100%;
          transform: translate(-12px, -50%);
          width: 24px;
          height: 24px;
          border-radius: 50%;
          border: 2px solid white;
          background: #1775e0;
        }
      }
    }

    .time-indicators {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      font-size: 12px;
      font-weight: 800;
      color: #e0e0e0;
      user-select: none;
    }
  }
}
