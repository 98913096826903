.roles-selection {
  gap: 40px;
  align-items: center;
  justify-content: center;

  .simple-button {
    flex: 1;
    width: 25%;
  }
}
