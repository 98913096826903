.range-date {
  span {
    display: inherit;
  }
}
.date-range-filter {
  position: fixed;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 1.5px 5px 0px #2317051c;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1000;

  button.close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    color: #1976d2;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .pickers {
    display: flex;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 8px;
    border-top: 1px solid #f5f5f5;

    button {
      background: none;
      border: none;
      cursor: pointer;
      padding: 8px;
      margin-left: 8px;
      border-radius: 8px;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}
