@import "assets/css/constants.scss";

.communication {
  display: flex;
  flex-direction: column;
  padding: 8px;
  justify-content: space-between;
  height: 100%;
  overflow: auto;

  .main-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 12px;

    .title {
      font-weight: 200;
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      color: #212731;

      .go-back {
        display: flex;
        gap: 8px;
        align-items: center;

        h3 {
          font-size: 24px;
          font-weight: 800;
        }

        > button {
          display: flex;
          align-items: center;
        }
      }

      h5 {
        margin-left: 32px;
        font-size: 18px;
        font-weight: 500;
        color: #a3adc2;
      }
    }

    .action {
      display: flex;
      flex-direction: column;
      background-color: #f5f9ff;
      border-radius: 12px;
      padding: 8px;

      button:disabled {
        cursor: not-allowed;
      }

      > h4 {
        font-weight: 800;
        font-size: 18px;
        color: #212731;
        margin-bottom: 6px;
      }

      > .MuiFormControlLabel-root {
        margin-right: 0;
        .MuiCheckbox-root {
          padding: 4px;
          margin-left: 5px;
          color: #d7e1f3;

          &.Mui-checked {
            color: $color-purple-main;
          }
        }
      }

      > .MuiFormGroup-root {
        display: flex;
        flex-direction: row;

        .MuiRadio-root {
          span > svg {
            fill: #bac5dc;
          }
          span svg:nth-of-type(2) {
            fill: $color-purple-main;
          }
        }

        .Mui-checked span {
          color: $color-purple-main;
        }
      }

      > .input {
        display: flex;
        gap: 6px;
      }
    }

    .submit {
      font-weight: 400;
      padding: 2px 16px;
      margin-top: 8px;
      height: 32px;
      min-height: 32px;
      max-height: 32px;
      width: fit-content;
      font-size: 14px;
      text-transform: none;
      align-self: self-end;
      border-radius: 8px;
    }
  }

  .emergency-stop {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    gap: 8px;
    padding: 8px 24px;
    border-radius: 8px;
    background-color: #da4238;
    color: white;
    transition: filter 0.2s ease;

    &:hover:not(:disabled) {
      filter: brightness(90%);
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}
