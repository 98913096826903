@import "assets/css/constants.scss";
@import "assets/fonts/fonts.scss";

* {
  :not(tspan) {
    font-family: Avenir, Arial, Helvetica, sans-serif !important;
  }
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
  outline: none;

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    button {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }

    #app {
      height: 100vh;
      padding-top: $topbar-height;
      background: #f5f9ff;

      .page {
        height: 99%;
        display: flex;
        flex-direction: column;
        padding: 24px;
        max-width: 1320px;
        margin: 0 auto;
        justify-content: center;
      }

      .d-flex {
        display: flex;
      }

      .flex-column {
        flex-direction: column;
      }

      .justify-content-center {
        justify-content: center;
      }

      .justify-content-around {
        justify-content: space-around;
      }
      .align-items-center {
        align-items: center;
      }

      .h-100 {
        height: 100%;
      }

      .w-100 {
        width: 100%;
      }

      .position-relative {
        position: relative;
      }

      .d-none {
        display: none;
      }
    }
  }
}
