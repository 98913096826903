@use "assets/css/constants.scss" as *;

@keyframes blink {
  0% {
    background-color: rgba($color-purple-main, 0.1);
  }
  50% {
    background-color: $color-purple-main;
  }
  100% {
    background-color: rgba($color-purple-main, 0.1);
  }
}

.custom-toggle {
  > span {
    color: #525967;
  }

  > .switch {
    height: 32px;
    margin-top: 4px;
    display: flex;
    border: 1px solid $color-purple-main;
    border-radius: 25px;
    overflow: hidden;
    align-items: center;

    &:has(:disabled) {
      border: 1px solid rgba($color-purple-main, 0.4);
    }

    .option {
      height: 32px;
      transition: all 0.2s ease;
      flex: 1;
      cursor: pointer;
      background-color: white;
      color: $color-purple-main;
      border: none;
      outline: none;
      font-size: 16px;
      font-weight: 800;

      &.active {
        background-color: $color-purple-main;
        color: white;

        &.blink {
          animation: blink 1.3s infinite;
        }
      }
    }
  }
}
