@import "assets/css/constants.scss";

.train-details {
  padding: 8px 8px 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 8px;

  .title {
    font-weight: 200;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    color: #212731;

    .go-back {
      display: flex;
      gap: 8px;
      align-items: center;

      h3 {
        font-size: 24px;
        font-weight: 800;
      }

      > button {
        display: flex;
        align-items: center;
      }
    }

    h5 {
      margin-left: 32px;
      font-size: 18px;
      font-weight: 500;
      color: #a3adc2;
    }
  }

  .info-wrapper {
    background-color: #f5f9ff;
    border-radius: 12px;
    padding: 24px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 48px;
    row-gap: 8px;

    .field-container {
      flex: 0 1 calc(50% - 24px);
      gap: 4px;
      display: flex;
      flex-direction: column;

      .label {
        font-size: 12px;
        color: #525967;
      }

      .value {
        color: #212731;
      }

      &:nth-last-child(-n + 2) {
        flex: 0 1 100%;
      }
    }
  }

  .circulation {
    margin-top: 20px;

    > span {
      color: #525967;
      margin-bottom: 8px;
    }
    .toggle-switch {
      margin-top: 8px;
      display: flex;
      border: 1px solid $color-purple-main;
      border-radius: 25px;
      overflow: hidden;

      .option {
        flex: 1;
        padding: 8px;
        text-align: center;
        cursor: pointer;
        background-color: white;
        color: $color-purple-main;
        border: none;
        outline: none;
        font-size: 16px;
        transition: background-color 0.3s, color 0.3s;
        font-weight: 800;

        &:not(.active):hover {
          background-color: #f0f0f0;
        }
        &.active {
          background-color: $color-purple-main;
          color: white;
        }

        &:disabled {
          cursor: not-allowed;
        }
        &:last-of-type {
          &:disabled {
            animation: blink 1s infinite;

            @keyframes blink {
              0%,
              100% {
                background-color: $color-purple-main;
              }
              50% {
                background-color: white;
              }
            }
          }
        }
      }
    }
  }

  .train-board {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;

    > div {
      display: flex;
      gap: 12px;
      flex-direction: column;

      .actions {
        border-radius: 8px;
        height: 42px;
        padding: 12px;
        width: 100%;
        color: #ffffff;
        background-color: $color-purple-main;
        align-items: center;
        display: flex;
        border: none;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        font-weight: 400;

        &:hover(:not(:disabled)) {
          cursor: pointer;
        }

        &:disabled {
          cursor: not-allowed;
        }

        &.centered {
          justify-content: center;
          border-radius: 12px;
        }
      }
    }

    .maneuver-pairing {
      margin-bottom: 8px;

      > div {
        display: flex;
        width: 100%;
        gap: 8px;

        button.simple-button {
          flex: 1;
        }
      }
    }
  }
}
