.custom-autocomplete {
  width: 100%;

  &:has(input:disabled) {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:disabled {
    background-color: #f0f3f8;
    color: #a3adc2;
  }

  input {
    color: #212731;
  }

  div.MuiFormControl-root {
    input.MuiInputBase-input {
      padding: 0px;
    }
  }

  li.MuiAutocomplete-option {
    &[aria-selected="true"] {
      background-color: rgb(110 98 229 / 20%) !important;
    }
  }

  .MuiAutocomplete-clearIndicator {
    color: #6e62e5;
  }
}
