@import "assets/css/constants.scss";

.panels-manager {
  .panel-title {
    font-size: 24px;
    font-weight: 600;
  }

  .train-panel {
    overflow: auto;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 8px;

    button {
      border-radius: 12px;
      height: 56px;
      padding: 12px;
      width: 100%;
      background-color: $color-snci-bg;
      border: none;
      text-align: start;
      font-size: 14px;
      color: #212731;
      transition: filter 0.2s ease;

      .info svg {
        background-color: $color-purple-main;
        border-radius: 4px;
      }

      &.twin {
        background-color: #fef9f0;

        .info svg {
          background-color: $color-info;
        }

        .untwin-detail {
          font-weight: 350;
          font-size: 14px;
          color: #a3adc2;
        }
      }

      &:hover {
        filter: brightness(90%);
        cursor: pointer;
      }

      .info {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > .index {
          display: flex;
          gap: 4px;
        }

        svg rect {
          fill: $color-purple-main;
        }
      }
    }
  }
}
