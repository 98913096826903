@use "assets/css/constants.scss" as *;

.moving-conditions {
  display: flex;
  flex-direction: column;
  gap: 12px;

  overflow: hidden;

  > .warning {
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 1px 4px 0px #0000001a;

    > div {
      width: 5px;
      background-color: $color-valid;
    }

    > .message {
      flex: 1;
      padding: 5px 20px;
      background-color: white;
      font-size: 14px;
    }
  }

  > .current-conditions {
    background-color: #f5f9ff;
    padding: 8px;
    border-radius: 12px;
    gap: 12px;
    display: flex;
    flex-direction: column;
  }
}
