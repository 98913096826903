@import "assets/css/constants.scss";

.ribbed-light {
  width: 100%;
  height: 110px;
  background-color: $color-snci-bg;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  gap: 16px;
  font-size: 18px;
  border-radius: 12px;

  .circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid black;
    background: repeating-linear-gradient(90deg, black 0px 5px, white 5px 10px);

    &.white {
      background: white;
    }
  }
}
