.time-controller {
	display: flex;
	align-items: center;
	.slider-wrapper {
		width: 270px;
		display: flex;
		flex-direction: column;
		height: 40px;
		margin-right: 32px;		
		> div {
			font-size: 12px;
		}
	}
	.MuiSlider-markLabel {
		top: 5px;
		right: -38px;
		left: auto !important;
		font-size: 10px;
		color: #1976d2;
	}
	.MuiSlider-valueLabelOpen {
		background: transparent;
		color: black;
		transform: translateY(105%) scale(1) !important;
		font-size: 12px;
		}
	.MuiSlider-valueLabelOpen:before {
		display: none,
	}
}