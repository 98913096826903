@import "assets/css/constants.scss";

.transit-list {
  padding: 8px;
  gap: 12px;
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 200;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    color: #212731;

    .go-back {
      display: flex;
      gap: 8px;
      align-items: center;

      h3 {
        font-size: 24px;
        font-weight: 800;
      }

      > button {
        display: flex;
        align-items: center;
      }
    }

    h5 {
      margin-left: 32px;
      font-size: 18px;
      font-weight: 500;
      color: #a3adc2;
    }
  }

  .action {
    display: flex;
    flex-direction: column;
    background-color: #f5f9ff;
    border-radius: 12px;
    padding: 12px;
    gap: 4px;

    .label {
      font-size: 18px;
      font-weight: 800;

      color: #212731;
    }
  }
}
