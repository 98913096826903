.controls {
  display: flex;
  gap: 60px;
  overflow-x: auto;

  > button {
    min-width: 96px;
    width: 96px;
  }

  button.simple-button.MuiButtonBase-root {
    &.active {
      background-color: #423b89;
    }
  }

  .speed {
    display: flex;
    gap: 8px;

    button.simple-button.MuiButtonBase-root {
      min-width: 16px;
      max-width: 48px;
      padding: 2px 16px;
    }
  }
}
