.action {
  .multiple {
    display: flex;
    gap: 6px;

    label span {
      color: #212731;
    }

    .error-message {
      svg {
        display: flex;
        flex-shrink: 0;
      }
    }
  }
}
