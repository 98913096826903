nav.tab-list {
  flex: 1 1 0%;
  margin-left: 1rem;
  height: 100%;
  display: flex;

  >ul, >ul>li {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0;
    margin-left: 1rem;
  }

  a {
    height: 100%;
  }
}