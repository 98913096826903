@use "assets/css/constants.scss" as *;

.close-popup {
  display: flex;
  flex-direction: column;
  gap: 32px;

  .header {
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 28px;
    }
  }

  .warning-action {
    display: flex;
    gap: 8px;
    color: $color-error;
    border: 1px solid $color-error;
    border-radius: 4px;
    padding: 16px;
  }

  .action-buttons {
    display: flex;
    gap: 16px;
    justify-content: space-between;

    button {
      font-weight: 400;
    }
  }
}
