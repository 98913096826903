/* * * * COLORS * * * */
$color-main: #1976d2;
$color-secondary: #f4f3f7;
$color-error: #da4238;
$color-purple-main: #6e62e5;
$color-purple-alt: #6e62e540;
$color-valid: #43cf7b;
$color-info: #fab142;
$color-snci-bg: #f5f9ff;
$color-black-main: #212731;
$color-white: #ffffff;

/* * * * SIZES * * * */
$topbar-height: 56px;
