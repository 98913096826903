.history {
  height: 100%;

  .simulation-all {
    background-color: inherit;
    border: none;
    color: #256eff;
    margin-left: 12px;
    text-decoration: underline;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
  }

  .wrapper {
    padding: 48px 56px;
    height: calc(99% - 90px);
    overflow: auto;

    .buttons {
      display: flex;
      justify-content: flex-end;
      flex: 1;
      gap: 18px;
      margin-right: 56px;

      .MuiBadge-badge {
        color: #ffffff;
        background-color: orangered;
      }
    }
  }
}
