@use "assets/css/constants.scss" as *;

.maneuver-mode {
  padding: 8px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .main {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .title {
      font-weight: 200;
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      gap: 8px;
      color: #212731;

      h3 {
        font-size: 24px;
        font-weight: 800;
      }

      h5 {
        font-size: 18px;
        font-weight: 500;
        color: #a3adc2;
      }
    }

    > .warning {
      display: flex;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0px 1px 4px 0px #0000001a;

      &:has(.information) {
        flex-wrap: wrap;

        > div {
          background-color: $color-info;
        }
        .information {
          flex-direction: column;
          flex: 1;
          padding: 5px 20px;
          background-color: white;

          .message {
            padding: 0;
          }
        }

        > div.message {
          width: 100%;
        }
      }

      > div {
        width: 5px;
        background-color: $color-valid;
      }

      .message {
        flex: 1;
        padding: 5px 20px;
        background-color: white;
        font-size: 14px;

        &.bold {
          font-weight: 800;
        }
      }
    }

    h3 {
      font-size: 18px;
      font-weight: 800;
    }

    .info {
      background-color: #f5f9ff;
      border-radius: 12px;
      padding: 24px 20px;
      display: flex;
      flex-wrap: wrap;
      column-gap: 48px;
      row-gap: 12px;

      .field-container {
        gap: 4px;
        display: flex;
        flex: 1;
        flex-direction: column;

        .label {
          font-size: 12px;
          color: #525967;
        }

        .value {
          color: #212731;

          &.bold {
            font-weight: 800;
          }
        }
      }

      button {
        font-weight: 400;
      }
    }

    .separator {
      border: 1px solid #eef3fb;
    }
  }

  .secondary-actions {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

    &:has(.crossing) {
      gap: 32px;
    }

    .submit {
      font-weight: 400;
      padding: 2px 16px;
      margin-top: 8px;
      height: 32px;
      min-height: 32px;
      max-height: 32px;
      width: fit-content;
      font-size: 14px;
      text-transform: none;
      align-self: self-end;
      border-radius: 8px;
    }

    .crossing {
      display: flex;
      margin-top: 16px;
      height: 48px;
      gap: 16px;

      .custom-autocomplete {
        flex: 1;
      }

      button {
        height: 100%;
      }

      .send {
        height: 100%;
        max-height: 100%;
      }
    }
  }

  .leave-actions {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 16px;

    button {
      width: 100%;
    }
  }

  .leave-mode {
    width: 100%;
  }
}
