@import 'assets/css/constants.scss';

.checkboxes {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > .wrapper {
    display: flex;
    align-items: center;
  }
}

