.tst {
  .toolbar {
    background: #3d3d3d;
    padding: 10px 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .MuiPaper-root {
    border-radius: 0;
  }
}
