@use "assets/css/constants.scss" as *;

.custom-input {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 4px;

  color: #a3adc2;

  .error-message {
    display: flex;
    flex-direction: row;
    gap: 6px;
    color: $color-error;
  }

  .MuiInputBase-root {
    width: 100%;
    background-color: white;
    height: 48px;

    input.MuiInputBase-input {
      padding-left: 8px;
    }

    fieldset {
      border-color: #d7e1f3;
    }

    &:hover {
      &:not(:has(input:disabled)) {
        fieldset {
          border-color: #6e62e5;
        }
      }
    }

    &:has(input:disabled) {
      cursor: not-allowed;

      > input {
        cursor: not-allowed;
      }
    }

    &.Mui-focused {
      fieldset {
        border-color: #6e62e5;
      }
    }
  }

  div.MuiFormControl-root.error {
    fieldset {
      border-color: $color-error;
    }
  }
}
