@import "assets/css/constants.scss";

.lock-canceller {
  width: 100%;
  padding: 8px;
  background-color: $color-snci-bg;
  border-radius: 12px;
  gap: 16px;
  display: flex;
  flex-direction: column;

  .used {
    gap: 4px;
    display: flex;
    color: #6e62e5;
    font-weight: 400;

    svg path {
      fill: #6e62e5;
    }
  }

  .submission {
    display: flex;
    justify-content: flex-end;
  }
}
