@import "assets/css/constants.scss";

.authorization-details {
  padding: 8px 8px 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 8px;

  .title {
    font-weight: 200;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    color: #212731;

    .go-back {
      display: flex;
      gap: 8px;
      align-items: center;

      h3 {
        height: 30px;
        font-size: 24px;
        font-weight: 800;
      }

      > button {
        display: flex;
        align-items: center;
      }
    }

    h5 {
      margin-left: 32px;
      font-size: 18px;
      font-weight: 500;
      color: #a3adc2;
    }
  }

  > .content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 8px;
  }
}
