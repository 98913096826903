@font-face {
  font-family: Avenir;
  src: url(avenir/avenir-lighter.woff);
  font-weight: 300;
}

@font-face {
  font-family: Avenir;
  src: url(avenir/avenir-book.woff);
  font-weight: 400;
}

@font-face {
  font-family: Avenir;
  src: url(avenir/avenir-medium.woff);
  font-weight: 500;
}

@font-face {
  font-family: Avenir;
  src: url(avenir/avenir-black.woff);
  font-weight: 900;
}

@font-face {
  font-family: "Inter";
  src: url(Inter-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "DigitalNumbers-Regular";
  src: url(DigitalNumbers-Regular.ttf) format("truetype");
}

@font-face {
  font-family: UnifontExMono;
  src: url(UnifontExMono.woff) format("woff");
}

@font-face {
  font-family: "DigitalNumbers-Regular";
  src: url(DigitalNumbers-Regular.ttf) format("truetype");
}
