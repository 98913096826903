@use "assets/css/constants.scss" as *;

.modal {
  width: 30%;

  .error-popup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 36px;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fdefda;
      color: red;
      height: 10vh;
      width: 100%;
      padding: 10px;

      svg {
        font-size: 72px;
      }
    }

    .content-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 36px;

      .header {
        font-size: 1.5rem;
        font-weight: 800;
      }
    }
  }

  .leave-buttons {
    display: flex;
    gap: 14px;

    > button.simple-button {
      text-decoration: none;
      background-color: $color-error;
      border-radius: 12px;
      color: #ffffff;
      padding-left: 28px;
      padding-right: 28px;

      &:hover {
        filter: brightness(90%);
      }
    }
  }
}
