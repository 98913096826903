@use "assets/css/constants.scss" as *;

.mission-change {
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 10px;

  .title {
    font-weight: 200;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    color: #212731;

    .go-back {
      display: flex;
      gap: 8px;
      align-items: center;

      h3 {
        font-size: 24px;
        font-weight: 800;
      }

      > button {
        display: flex;
        align-items: center;
      }
    }

    h5 {
      margin-left: 32px;
      font-size: 18px;
      font-weight: 500;
      color: #a3adc2;
    }
  }

  > .warning {
    display: flex;
    flex-shrink: 0;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 1px 4px 0px #0000001a;

    > div {
      width: 5px;
      background-color: $color-valid;
    }

    .message {
      flex: 1;
      padding: 5px 20px;
      background-color: white;
      font-size: 14px;

      &.bold {
        font-weight: 800;
      }
    }
  }

  .info {
    justify-content: flex-end;
    width: 100%;
    background-color: #f5f9ff;
    border-radius: 12px;
    padding: 24px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 48px;
    row-gap: 12px;

    &:last-of-type {
      padding: 8px;
      margin-bottom: 12px;
    }

    .field-container {
      gap: 4px;
      display: flex;
      flex: 1;
      flex-direction: column;

      .label {
        font-size: 12px;
        color: #525967;
      }

      .value {
        color: #212731;

        &.bold {
          font-weight: 800;
        }
      }
    }

    .action {
      gap: 4px;
      display: flex;
      flex-direction: column;
      width: 100%;

      .label {
        font-size: 12px;
        color: #525967;
      }

      .value {
        color: #212731;

        &.bold {
          font-weight: 800;
        }
      }
    }
  }

  .submit {
    font-weight: 400;
    padding: 2px 16px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;
    width: fit-content;
    font-size: 14px;
    text-transform: none;
    align-self: self-end;
    border-radius: 8px;
  }
}
