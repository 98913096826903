.transit-cancellation {
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 10px;

  .title {
    font-weight: 200;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    color: #212731;

    .go-back {
      display: flex;
      gap: 8px;
      align-items: center;

      h3 {
        font-size: 24px;
        font-weight: 800;
      }

      > button {
        display: flex;
        align-items: center;
      }
    }

    h5 {
      margin-left: 32px;
      font-size: 18px;
      font-weight: 500;
      color: #a3adc2;
    }
  }

  .action {
    display: flex;
    flex-direction: column;
    background-color: #f5f9ff;
    border-radius: 12px;
    padding: 8px;
    gap: 24px;

    > h4 {
      font-size: 18px;
      font-weight: 800;
    }

    .counter {
      align-self: center;
      height: 92px;
      width: calc(100% - 56px);
      justify-content: center;
      background-color: #474747;
      border-radius: 8px;
      display: flex;

      .digit-container {
        align-items: center;
        display: inline-flex;
        gap: 8px;

        .number {
          color: #d1d1d1;
          font-size: 32px;
          width: 32px;
          height: 48px;
          text-align: center;
          border-radius: 2px;

          span {
            height: 100%;
            border: 2px solid #373737;
            font-family: Barlow;
            background-color: #000000;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 100%;
          }
        }
      }
    }
  }
}
