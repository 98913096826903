.clock-time {
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
  height: 40px;
  width: 130px;
  display: flex;
  align-items: center;
  border: 1px #d7e1f3 solid;
  border-radius: 4px;
  justify-content: flex-end;
  padding-right: 8px;
  background: white;
  margin-left: 32px;
  margin-right: 32px;
  position: relative;

  .icon {
    position: absolute;
    left: 10px;
  }

  b.empty {
    letter-spacing: 3px;
  }
}
