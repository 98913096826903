.student-modal {
  .div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    gap: 20px;

    .simple-button {
      width: 25%;
    }
  }
}
