@import "assets/css/constants.scss";

.alt-light {
  width: 100%;
  height: 90px;
  background-color: $color-snci-bg;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  gap: 16px;
  font-size: 18px;
  border-radius: 12px;

  .circle {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid #464a4e;
    background: #464a4e;

    &.on {
      box-shadow: 0 0 30px #ffe500;
      background: #ffe500;
    }
  }
}
