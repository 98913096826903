@import 'assets/css/constants.scss';

@mixin box-modifier($color, $fontColor, $borderColor, $class) {
  &.box--#{$class} {
    background: $color;
    color: $fontColor;
    border: 1px solid $borderColor;

    &:hover {
      background: darken($color, 5%);
    }
  }
};

.select-box {
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-height: 70px;
  min-width: 230px;
  max-width: 230px;
  text-align: center;
  font-weight: 400;
  border-radius: 6px;
  box-shadow: 0px 1px 7.699999809265137px -2px #00000026;
  overflow-wrap: anywhere;

  &:hover {
    outline: 0;
    text-decoration: none;
    box-shadow: none;
  }

  @include box-modifier(#3A4457, #FFF, transparent, selected);
  @include box-modifier(#FFF, #000, transparent, unselected);
}